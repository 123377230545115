@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'circular';
    src: url('../public/fonts/Book.woff2') format('woff2');
    font-display: block;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }
  @font-face {
    font-family: 'circular';
    src: url('../public/fonts/Medium.woff2') format('woff2');
    font-display: block;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
  }
  @font-face {
    font-family: 'circular';
    src: url('../public/fonts/Bold.woff2') format('woff2');
    font-display: block;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }
}

@layer components {
  :root {
    --font-circular: circular;
    --color-50: #d63d00;
    --color-grey-100: #0d0d0d;
    --color-grey-80: #4b4b4b;
    --color-grey-60: #707070;
    --color-grey-40: #afafaf;
    --color-grey-20: #dcdcdc;
    --color-inverse: #ffffff;
    --color-background: #fcfdff;
    --color-buttons: #000000;
  }
  .style-heading-xl {
    @apply text-size2 font-bold tracking-tightXXL lg:text-size0;
  }
  .style-heading-l {
    @apply text-size4 font-bold tracking-tightXL lg:text-size1;
  }
  .style-heading-m {
    @apply text-size6 font-bold tracking-tightXL lg:text-size2;
  }
  .style-heading-s {
    @apply text-size8 font-bold tracking-tightL lg:text-size3;
  }
  .style-heading-xs {
    @apply text-size9 font-bold tracking-tightM lg:text-size5;
  }
  .style-heading-xxs {
    @apply text-size12 font-bold tracking-tightM lg:text-size7;
  }
  .style-heading-xxxs {
    @apply text-size15 font-bold tracking-tightS lg:text-size10;
  }
  .style-body-l {
    @apply text-size11 tracking-tightXS;
  }
  .style-body-m {
    @apply text-base tracking-tightXS;
  }
  .style-body-s {
    @apply text-size14 tracking-tightXS;
  }
  .style-link-m {
    @apply text-base font-medium tracking-tightXXS;
  }
  .style-link-s {
    @apply text-size16 font-medium tracking-tightXS;
  }
  .style-button {
    @apply text-base font-bold tracking-tightXS;
  }

  /*
   * How to use:
   *
      <div className="flex flex-row justify-center" />
        <div className={`grid-standard `} />
          <div className={` col-span-4 lg:col-span-12 `} />
   */
  .grid-standard {
    @apply mx-[24px] grid grid-cols-[repeat(4,_minmax(0px,_232px))] gap-[16px] lg:mx-[84px] lg:grid-cols-[repeat(12,_minmax(0px,_116px))] lg:gap-[32px];
  }

  html ::-webkit-scrollbar,
  html * ::-webkit-scrollbar {
    width: 7px;
  }
  html ::-webkit-scrollbar-thumb,
  html * ::-webkit-scrollbar-thumb {
    background: #000;
  }
  html ::-webkit-scrollbar-track,
  html * ::-webkit-scrollbar-track {
    background: #ddd;
  }
}

@layer utilities {
  .link-underline span.button-label {
    border-bottom-width: 0;
    background-image: linear-gradient(#d63d00, #d63d00);
    background-size: 0 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.25s ease-in-out;
  }
  .link-underline:hover span.button-label {
    background-size: 100% 2px;
    background-position: 0 100%;
  }
  .primary-arrow span,
  .secondary-arrow span {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
  }
  .primary-arrow span {
    background-image: url('images/arrow-forward.svg');
  }
  .secondary-arrow span {
    background-image: url('images/arrow-forward-alt.svg');
  }
  .arrow-focus span {
    background-image: url('images/arrow-forward-50.svg');
  }
  .arrow-disabled span {
    background-image: url('images/arrow-forward-grey.svg');
  }
  .d-arrow {
    background-image: url('images/arrow-down-dark.svg');
  }
  .l-arrow {
    background-image: url('images/arrow-down.svg');
  }
  .chevron-open {
    animation-duration: 500ms;
    animation-name: openAccord;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    /*background-image: url('images/minus.svg');*/
  }
  .chevron-closed {
    animation-duration: 500ms;
    animation-name: closeAccord;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    /*background-image: url('images/add.svg');*/
  }
  span.arrow-down {
    height: 20px;
    width: 17px;
  }
  /* Fading in of elements BEGIN */
  .fade-in-section {
    opacity: 0;
    transform: translateY(50px);
    visibility: hidden;
    /*transition: opacity 1000ms ease-out, transform 500ms ease-out, visibility 1000ms ease-out;*/
    transition: opacity 1200ms, transform 600ms, visibility 1200ms;
    will-change: opacity, transform, visibility;
  }
  .fade-down {
    transform: translateY(-20vh);
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  /* Fading in of elements END */
  /* Bouncing of elements BEGIN */
  @keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  .bounceElement {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  .bounceElement:hover {
    cursor: pointer;
    animation-name: bounce;
    -moz-animation-name: bounce;
  }
  /* Bouncing of elements END */

  /* Triangle */
  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.65rem 0 0.65rem 1rem;
  }

  /* Blur effect for logo */
  .zeroRing {
    background-color: rgba(255, 255, 255, 0.04);
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    color: #fff;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    -webkit-mask-image: radial-gradient(
      circle,
      #0000 35%,
      rgba(0, 0, 0, 1) 35%
    );
    mask-image: radial-gradient(circle, #0000 35%, rgba(0, 0, 0, 1) 35%);
    backdrop-filter: blur(40px);
  }
  .noblur {
    backdrop-filter: none;
  }

  /* SWIRL BEGIN */
  .slidesContainer {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .swirlContainer {
    /*opacity:0;*/
    background: black;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    letter-spacing: -1px;
  }
  .swirlContainer div,
  .productSwirlContainer div {
    background-position: center;
    background-repeat: no-repeat;
    zoom: 1;
    background-image: inherit;
  }
  .swirlContainer > div:first-child {
  }
  .swirlContainer > div:first-child > div {
  }
  .swirlContainer div:nth-child(2) {
    filter: blur(20px) brightness(0.97);
    border-radius: 50%;
    backdrop-filter: blur(0);
    background-color: rgba(255, 255, 255, 0.04);
    background-blend-mode: color;
  }
  .swirlContainer div:nth-child(3) {
    position: absolute;
    border-radius: 50%;
  }
  .swirlContainer .outerContainer {
    animation-duration: 1600ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
  }
  .swirlContainer .outerContainer > div {
    transform: scale(1.2);
    animation-duration: 1500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
  }
  .swirlContainer .outerRing,
  .productSwirlContainer .outerRing {
    animation-duration: 1400ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-delay: 125ms;
    animation-fill-mode: forwards;
  }
  .swirlContainer .innerRing {
    animation-duration: 1200ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
  .fadeIn {
    animation-name: fadeInSwirl;
    animation-duration: 1800ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
  }
  .fadeOut {
    animation-name: fadeOutSwirl;
    animation-duration: 1800ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
  }
  .fadeed {
    animation-name: fadeOutSwirl;
    animation-duration: 3800ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
  }
  .mainMenu {
    z-index: 5;
  }
  .logoInitial {
    background-position: calc(100% + 40px) 15px;
  }
  .logoClean {
    background-image: url('images/logoClean.svg');
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-position: right 15px;
    padding-right: 40px;
  }
  .moveText {
    overflow: hidden;
  }
  .moveText .move {
    position: relative;
    bottom: -1.5em;
    opacity: 1;
    animation: move-text 0.5s forwards;
  }
  .unMoveText .move {
    position: relative;
    bottom: 0;
    opacity: 1;
    animation: un-move-text 0.15s forwards;
  }
  .moveTextInOut .move {
    position: relative;
    bottom: -1.5em;
    opacity: 1;
    /*animation: move-text-in-out 0.15s forwards;*/
    animation: move-text-in-out 2.5s forwards;
  }
  .max-video video {
    max-width: none;
    width: auto !important;
  }

  .closed .vertical {
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);
  }
  .closed .horizontal {
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);
  }
  .opened .vertical {
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg);
  }
  .opened .horizontal {
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg);
  }
  .circle-plus .circle .horizontal {
    width: 16px;
    height: 2px;
    margin-left: -8px;
    margin-top: -1px;
  }
  .circle-plus .circle .vertical {
    width: 2px;
    height: 16px;
    margin-left: -1px;
    margin-top: -8px;
  }
  .circle-plus,
  .circle-plus * {
    filter: none !important;
    backdrop-filter: none !important;
  }

  .menuBtn path {
    fill: #0d0d0d;
  }
  .menuBtnDark path {
    fill: white;
  }

  .sonar-wave1 {
    animation: sonarWave 4s linear infinite;
  }

  .sonar-wave2 {
    animation: sonarWave 4s 2s linear infinite;
  }

  .progress-ring {
    scale: 1.5;
  }

  .gallery-item {
    aspect-ratio: 1/1;
    border-radius: 50%;
    height: 100% !important;
    width: auto !important;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-name: spinner_gallery;
  }

  .gallery-item-inactive {
    aspect-ratio: 16/9;
    border-radius: 2.5rem;
    height: 100% !important;
    width: auto !important;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-name: spinner_gallery_inactive;
  }

  .gallery-item-static {
    border-radius: 50%;
    height: 100% !important;
    aspect-ratio: 1/1;
    width: auto !important;
  }

  .keep-left {
    aspect-ratio: 16/9;
    border-radius: 2.5rem;
    height: 100% !important;
    width: auto !important;
  }

  .video-child {
    object-fit: cover;
  }

  .menuBar.before,
  .menuBar.after {
    transform: translate3d(-1px, -3px, 0) rotate(90deg);
  }
  .menuBar.after {
    transform: translate3d(-1px, 3px, 0) rotate(-90deg);
  }
  .header-menu-btn.is-selected .menuBar.before {
    transform: translate3d(-1px, 0px, 0) rotate(-45deg);
  }
  .header-menu-btn.is-selected .menuBar.after {
    transform: translate3d(-1px, 0px, 0) rotate(45deg);
  }

  .social-icons path {
    fill: #0d0d0d;
    transition: all 0.2s;
  }
  .social-icons:hover path {
    fill: #d63d00;
  }

  .select-carrot {
    background-image: url('images/carrot.svg');
    background-repeat: no-repeat;
    background-position: right center;
  }
  .react-player__play-icon,
  .react-player__shadow {
    display: none !important;
  }
  .expanse {
    position: fixed;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: #fafcff;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
  @media (orientation: landscape) {
    @keyframes expanseWave {
      0% {
        height: 0;
        width: 0;
        border-radius: 50%;
      }
      50% {
        border-radius: 50%;
      }
      100% {
        right: -25vw;
        top: -50vw;
        height: 175vw;
        width: 175vw;
        border-radius: 5%;
      }
    }
    @keyframes expanseWaveCollapse {
      0% {
        right: -25vw;
        top: -50vw;
        height: 175vw;
        width: 175vw;
        border-radius: 5%;
      }
      50% {
        border-radius: 50%;
      }
      100% {
        height: 0;
        width: 0;
        border-radius: 50%;
      }
    }
  }
  @media (orientation: portrait) {
    @keyframes expanseWave {
      0% {
        height: 0;
        width: 0;
        border-radius: 50%;
      }
      50% {
        border-radius: 50%;
      }
      100% {
        right: -50vh;
        top: -25vh;
        height: 175vh;
        width: 175vh;
        border-radius: 5%;
      }
    }
    @keyframes expanseWaveCollapse {
      0% {
        right: -50vh;
        top: -25vh;
        height: 175vh;
        width: 175vh;
        border-radius: 5%;
      }
      50% {
        border-radius: 50%;
      }
      100% {
        height: 0;
        width: 0;
        border-radius: 50%;
      }
    }
  }

  @keyframes sonarWave {
    from {
      opacity: 0.4;
    }
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
  @keyframes move-text-in-out {
    0% {
      bottom: -1.5em;
      opacity: 1;
    }
    20% {
      bottom: 0;
      opacity: 1;
    }
    80% {
      bottom: 0;
      opacity: 1;
    }
    100% {
      bottom: -1.5em;
      opacity: 1;
    }
  }
  @keyframes move-text {
    0% {
      bottom: -1.5em;
      opacity: 1;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
  @keyframes un-move-text {
    0% {
      bottom: 0;
      opacity: 1;
    }
    100% {
      bottom: -1.5em;
      opacity: 1;
    }
  }
  @keyframes openAccord {
    0% {
      background-image: url('images/add.svg');
    }
    100% {
      background-image: url('images/minus.svg');
    }
  }
  @keyframes closeAccord {
    0% {
      background-image: url('images/minus.svg');
    }
    100% {
      background-image: url('images/add.svg');
    }
  }
  @keyframes fadeInSwirl {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOutSwirl {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes spinner_gallery {
    0% {
      transform: rotate(0deg) translateZ(0);
    }
    30% {
      aspect-ratio: 1/1;
      border-radius: 50%;
    }
    100% {
      aspect-ratio: 16/9;
      border-radius: 2.5rem;
      /*transform: rotate(-360deg) translateZ(0);*/
      transform: rotate(0deg) translateZ(0);
    }
  }
  @keyframes spinner_gallery_inactive {
    0% {
      transform: rotate(0deg) translateZ(0);
    }
    70% {
      aspect-ratio: 1/1;
      border-radius: 50%;
    }
    100% {
      aspect-ratio: 1/1;
      border-radius: 50%;
      /*transform: rotate(360deg) translateZ(0);*/
      transform: rotate(0deg) translateZ(0);
    }
  }
  @keyframes spinner_page {
    from {
      transform: rotate(0deg) translateZ(0);
    }
    to {
      transform: rotate(360deg) translateZ(0);
    }
  }
  @keyframes spinner_page_alt {
    from {
      transform: rotate(0deg) translateZ(0);
    }
    to {
      transform: rotate(360deg) translateZ(0);
    }
  }
  @keyframes spinner_page_backward {
    from {
      transform: rotate(0deg) translateZ(0);
    }
    to {
      transform: rotate(-360deg) translateZ(0);
    }
  }
  @keyframes spinner_page_backward_alt {
    from {
      transform: rotate(0deg) translateZ(0);
    }
    to {
      transform: rotate(-360deg) translateZ(0);
    }
  }
  @keyframes zoomBG_page {
    0% {
      transform: scale(1.2) translateZ(0);
    }
    50% {
      transform: scale(3) translateZ(0);
    }
    100% {
      transform: scale(1.2) translateZ(0);
    }
  }
  @keyframes zoomBG_page_alt {
    0% {
      transform: scale(1.2) translateZ(0);
    }
    50% {
      transform: scale(3) translateZ(0);
    }
    100% {
      transform: scale(1.2) translateZ(0);
    }
  }
  /* SWIRL END */
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #afafaf;
}
